import { ContactModel } from 'shared/types/Contacts';
import { COLOR } from '../constants/colors';
import {
    INVOICE_STATUS,
    INVOICES_STATUSES_DICTIONARY,
} from '../constants/invoices/statuses';
import Entry from '../db/services/Entry';
import { DBServiceOptionsWithImages } from '../types/dbService';
import { Identificator } from '../types/Identificator';
import { InvoiceModel } from '../types/Invoice';

import { getLocal, isFuture } from './date';

export const generateReference = () => {
    let reference = 'EQ';

    const MIN = 10000;
    const MAX = 99999;
    const randomNumber = Math.floor(Math.random() * (MAX - MIN + 1)) + MIN;
    const date = getLocal().toFormat('yyyyLLdd');

    reference = reference + date + randomNumber;

    return reference;
};

export const calculateInvoiceSum = async ({
    selectedEntriesIds,
    products,
    taxRate,
    productsTaxRate,
    options,
}: {
    selectedEntriesIds: Identificator[] | undefined;
    products: { quantity: number; price: string }[] | undefined;
    taxRate?: number;
    productsTaxRate?: number;
    options: DBServiceOptionsWithImages;
}): Promise<{ subTotalSum: number; totalSum: number }> => {
    let proceduresSum = 0;
    let productsSum = 0;

    const entryService = new Entry({
        database: options.database,
        imageService: options.imageService,
        logDBAction: options.logDBAction,
    });

    if (selectedEntriesIds?.length) {
        await Promise.all(
            selectedEntriesIds.map(async (entryId) => {
                const entry = await entryService.getByID(entryId.id);
                const entryProcedures = await entry.entryProcedures.fetch();

                entryProcedures.forEach((ep) => {
                    const price = Number(ep.price);
                    if (!isNaN(price)) {
                        proceduresSum += price * ep.quantity;
                    }
                });
            }),
        );
    }

    if (products?.length) {
        products.forEach(({ price, quantity }) => {
            const priceNumber = Number(price);
            if (!isNaN(priceNumber)) {
                productsSum += priceNumber * quantity;
            }
        });
    }

    const proceduresTax =
        taxRate && !isNaN(taxRate) ? proceduresSum * (taxRate / 100) : 0;
    const productsTax =
        productsTaxRate && !isNaN(productsTaxRate)
            ? productsSum * (productsTaxRate / 100)
            : 0;

    const subTotalSum = proceduresSum + productsSum;
    const taxes = proceduresTax + productsTax;
    const totalSum = subTotalSum + taxes;

    return { subTotalSum, totalSum };
};

export const sumInvoiceEntryProceduresPrice = async (
    options: DBServiceOptionsWithImages,
    invoices: InvoiceModel[],
): Promise<number> => {
    const entryService = new Entry(options);

    let sum = 0;

    await Promise.all(
        invoices.map(async (invoice) => {
            const entries = await entryService.getByParam(
                'invoice_id',
                invoice.id,
            );
            await Promise.all(
                entries.map(async (entry) => {
                    const entryProcs = await entry.entryProcedures.fetch();
                    entryProcs.forEach((ep) => {
                        const price = Number(ep.price);
                        if (!isNaN(price)) {
                            sum += ep.quantity * price;
                        }
                    });
                }),
            );
        }),
    );

    return sum;
};

export const getInvoiceStatus = ({
    hasAccountingProvider,
    sentToContact,
    sentToContactTime,
    status,
    translateFn,
}: {
    hasAccountingProvider: boolean;
    translateFn: (translationKey: string) => string;
    status: string;
    sentToContactTime: string;
    sentToContact: boolean;
}): string => {
    try {
        if (!!hasAccountingProvider && status === INVOICE_STATUS.authorised) {
            if (sentToContactTime) {
                return translateFn(
                    INVOICES_STATUSES_DICTIONARY[
                        sentToContact
                            ? INVOICE_STATUS.sent
                            : INVOICE_STATUS.queued
                    ],
                );
            }
        }

        return translateFn(INVOICES_STATUSES_DICTIONARY[status.toUpperCase()]);
    } catch {
        return status;
    }
};

export const getInvoiceStatusColor = (
    status: string,
    dueDateTime: string,
): { boldText: boolean; color: string } => {
    let parsedStatus = '';

    if (status && dueDateTime) {
        if (
            !isFuture(dueDateTime, -1) &&
            [INVOICE_STATUS.authorised, INVOICE_STATUS.sent].includes(
                status as INVOICE_STATUS,
            )
        ) {
            parsedStatus = INVOICE_STATUS.overdue;
        } else {
            parsedStatus = status;
        }
    }

    switch (parsedStatus) {
        case INVOICE_STATUS.paid:
            return {
                boldText: true,
                color: COLOR.green,
            };
        case INVOICE_STATUS.draft:
            return {
                boldText: false,
                color: COLOR.light_gray,
            };
        case INVOICE_STATUS.overdue:
            return {
                boldText: true,
                color: COLOR.flamingo,
            };
        default:
            return {
                boldText: false,
                color: COLOR.black,
            };
    }
};

export const getInvoiceBusinessOrContactName = (contact?: ContactModel) => {
    if (!contact) {
        return '-';
    }

    const contactName = `${contact.firstName} ${
        contact.lastName ? `${contact.lastName}` : ''
    }`;

    return contact.businessName
        ? `${contactName} (${contact.businessName})`
        : contactName;
};

export const getInvoiceFileName = (invoiceNumber: string) =>
    `INV-${invoiceNumber}`;
