// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';
import { Model } from '@nozbe/watermelondb';
import { field, date, readonly } from '@nozbe/watermelondb/decorators';

setGenerator(uuidv4);

export default class RsvpOnboarding extends Model {
    static table = 'onboardings';

    @field('overlay_archived_invoices') isArchivedInvoicesOnboarded;
    @field('overlay_rsvp_one') isAppointmentOnboarded;
    @field('overlay_rsvp_two') isInvitationOnboarded;
    @field('overlay_rsvp_three') isBannerOnboarded;
    @field('overlay_rsvp_four')
    isDeclinedAppointmentsOnboarded;
    @field('getting_started_section_dismissed')
    dashboardGettingStartedSectionDismissed;
    @field('are_procedures_onboarded') areProceduresOnboarded;
    @field('are_entries_onboarded') areEntriesOnboarded;
    @field('are_invoices_onboarded') areInvoicesOnboarded;
    @field('are_business_details_onboarded') areBusinessDetailsOnboarded;

    @field('user_id') userId;

    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
}
