import { Collection, Database, Q } from '@nozbe/watermelondb';
import { v4 as uuidv4 } from 'uuid';

import Location from './Location';
import Organisation from './Organisation';
import OrganisationUser from './OrganisationUser';
import Region from './Region';
import { UserModel, UserPayload } from '../../types/User';
import { DBServiceOptionsWithImages } from '../../types/dbService';

class User {
    private database: Database;
    private collection: Collection<UserModel>;
    private table = 'users';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    get() {
        return this.collection.query();
    }

    getById(id: string) {
        return this.collection.query(Q.where('id', id));
    }

    getByEntryId(id: string) {
        return this.collection.query(Q.on('entry_users', 'entry_id', id));
    }

    async getColorById(user_id: string): Promise<string> {
        const organisationUserService = new OrganisationUser(this.database);
        const color = await organisationUserService.getColorByUserId(user_id);

        return color[0]?.code;
    }

    async getUserRegion(userId: string) {
        const regionService = new Region(this.database);

        const user = await this.getById(userId).fetch();
        const { region } = user[0];
        const regions = await regionService.getByParam('code', region).fetch();

        return regions[0];
    }

    getCurrentUserQuery() {
        return this.collection.query();
    }

    getActiveUsers() {
        return this.collection.query(
            Q.on('organisation_users', Q.where('active', true)),
        );
    }

    async update(userId: string, payload: UserPayload) {
        const { image, avatar, removeAvatar, location } = payload;
        const userElement = await this.getById(userId).fetch();
        const { id: userID } = userElement[0];

        const locationService = new Location({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const locationElement = await locationService.addUnique(
            location,
            userId,
        );

        const updatedUser = await this.database.write(async () => {
            const updatedUserElement = await userElement[0].update((user) => {
                user.firstName = payload.hasOwnProperty('firstName')
                    ? payload.firstName
                    : userElement[0].firstName;
                user.lastName = payload.hasOwnProperty('lastName')
                    ? payload.lastName
                    : userElement[0].lastName;
                user.companyName = payload.hasOwnProperty('companyName')
                    ? payload.companyName
                    : userElement[0].companyName;
                user.phone = payload.hasOwnProperty('phone')
                    ? payload.phone
                    : userElement[0].phone;
                user.phonePrefix =
                    payload.phonePrefix || userElement[0].phonePrefix;
                user.accountingProvider = payload.hasOwnProperty(
                    'accountingProvider',
                )
                    ? payload.accountingProvider
                    : userElement[0].accountingProvider;
                user.accountingDisconnectMessage =
                    payload.accountingDisconnectMessage ||
                    userElement[0].accountingDisconnectMessage;
                user.region = payload.region || userElement[0].region;
                user.address = payload.hasOwnProperty('address')
                    ? payload.address
                    : userElement[0].address;
                user.locationID = locationElement?.id
                    ? locationElement.id
                    : user.locationID;
                user.menuOrder = payload.menuOrder ?? user.menuOrder;
                user.freshchatRestoreId =
                    payload.freshchatRestoreId ?? user.freshchatRestoreId;
                user.isTeamCreated =
                    payload.isTeamCreated ?? user.isTeamCreated;
                user.timezone = payload.timezone ?? user.timezone;
                user.isProceduresAccountSetupSet =
                    payload.isProceduresAccountSetupSet ??
                    user.isProceduresAccountSetupSet;
                user.isInvoicingAccountSetupSet =
                    payload.isInvoicingAccountSetupSet ??
                    user.isInvoicingAccountSetupSet;
                user.isContactsSetup =
                    payload.isContactsSetup ?? user.isContactsSetup;
                user.isAppointmentOnboarded =
                    payload.isAppointmentOnboarded ??
                    user.isAppointmentOnboarded;
                user.isAppointmentContactSelectionOnboarded =
                    payload.isAppointmentContactSelectionOnboarded ??
                    user.isAppointmentContactSelectionOnboarded;
                user.isEntryCreationFromEventEnabled =
                    payload.isEntryCreationFromEventEnabled ??
                    user.isEntryCreationFromEventEnabled;
                user.termsAcceptedAt =
                    payload.termsAcceptedAt || user.termsAcceptedAt;
                user.isPlusButtonOnboarded =
                    payload.isPlusButtonOnboarded ?? user.isPlusButtonOnboarded;
            });

            this.options.logDBAction({
                message: 'Update user',
                modelName: this.table,
                payload: userElement[0],
            });

            return updatedUserElement;
        });

        if (removeAvatar) {
            await this.options.imageService.remove(userID);
        }

        if (image && image.uri) {
            const organisationService = new Organisation({
                database: this.database,
                imageService: this.options.imageService,
                logDBAction: this.options.logDBAction,
            });

            const organisation = await organisationService.get();
            const { id: organisationID } = organisation[0];

            this.options.imageService.uploadImage({
                image,
                entityID: userID,
                entityType: 'User',
                annotationImage: '',
                ownerID: userID,
                userIDs: [userID],
                documentID: avatar?.documentID,
                organisationID,
            });
        }

        return updatedUser;
    }

    async updateField(
        userId: string,
        fieldName: string,
        fieldValue: string | number | boolean,
    ) {
        const userElement = await this.getById(userId);

        await this.database.write(async () => {
            await userElement[0].update(
                (user) => (user[fieldName] = fieldValue),
            );

            this.options.logDBAction({
                message: `Update user field ${fieldName}`,
                modelName: this.table,
                payload: userElement[0],
            });
        });
    }

    async setProfiling(userId: string, value: boolean) {
        const userElement = await this.getById(userId);

        let traceID = uuidv4();

        if (!value) {
            traceID = '';
        }

        await this.database.write(async () => {
            await userElement[0].update((user) => {
                user.profiling = value;
                user.traceID = traceID;
            });

            this.options.logDBAction({
                message: 'User set profiling',
                modelName: this.table,
                payload: userElement[0],
            });
        });
    }
}

export default User;
