// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model } from '@nozbe/watermelondb';
import { field, date, readonly } from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class OrganisationAccountings extends Model {
    static table = 'organisation_accountings';

    @field('sales_account_code') salesAccountCode;
    @field('accounting_provider') accountingProvider;
    @field('accounting_disconnect_message') accountingDisconnectMessage;
    @field('lock_accounting_provider') lockAccountingProvider;
    @field('user_email') userEmail;
    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
}
