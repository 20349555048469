// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model } from '@nozbe/watermelondb';
import {
    field,
    date,
    readonly,
    relation,
} from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class Organisation extends Model {
    static table = 'organisations';

    static associations = {
        entries: { type: 'has_many', foreignKey: 'organisation_id' },
        events: { type: 'has_many', foreignKey: 'organisation_id' },
        horses: { type: 'has_many', foreignKey: 'organisation_id' },
        organisation_users: { type: 'has_many', foreignKey: 'organisation_id' },
        inventoryChanges: {
            type: 'has_many',
            foreignKey: 'organisation_id',
        },
        locations: { type: 'belongs_to', key: 'location_id' },
    };

    @field('name') name;
    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
    @field('email') email;
    @field('phone_number') phoneNumber;
    @field('phone_prefix') phonePrefix;
    @field('alternative_email') alternativeEmail;
    @field('alternative_phone_number') alternativePhoneNumber;
    @field('alternative_phone_prefix') alternativePhonePrefix;
    @field('address') address;
    @field('alternative_address') alternativeAddress;
    @field('footer_message') footerMessage;
    @field('tax_rate') taxRate;
    @field('products_tax_rate') productsTaxRate;
    @field('business_number') businessNumber;
    @field('payment_instructions') paymentInstructions;
    @field('location_id') locationID;
    @field('is_alternative_phone_enabled') isAlternativePhoneEnabled;
    @field('is_alternative_email_enabled') isAlternativeEmailEnabled;

    @relation('locations', 'location_id') locations;
}
