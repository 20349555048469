import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import Onboarding from 'shared/db/services/Onboarding';
import {
    OnboardingModel,
    OnboardingPayload,
    RsvpOnboardingState,
} from 'shared/types/RsvpOnboarding';

import { useDatabase } from '@/hooks';
import Logger from '@/services/logger';
import { Context } from '@/types/context';

import { useDBSyncContext } from './DBSyncContext';
import { useEventInvitationContext } from './EventInvitationContext';
import { useImagesContext } from './ImagesContext';
import { useUserContext } from './UserContext';

export type OverlayOption =
    | 'appointment'
    | 'invitation'
    | 'banner'
    | 'declinedAppointment';

const INITIAL_STATE: RsvpOnboardingState = {
    isAppointmentOnboarded: false,
    isInvitationOnboarded: false,
    isBannerOnboarded: false,
    isDeclinedAppointmentsOnboarded: false,
};

const RSVPOverlayContext = createContext<Context.RSVPOverlay.Value>({
    dismissOverlay: () => Promise.resolve(),
    showRSVPAppointmentOverlay: false,
    showRSVPBannerOverlay: false,
    showRSVPInvitationOverlay: false,
    showRSVPDeclinedAppointmentOverlay: false,
});

const RSVPOverlayProvider = ({ children }: Context.RSVPOverlay.Props) => {
    const { isSyncInProgress, isInitialSyncInProgress } = useDBSyncContext();
    const { attentionNeededEvents } = useEventInvitationContext();

    const [RSVPOnboardingState, setRSVPOnboardingState] =
        useState<RsvpOnboardingState>(INITIAL_STATE);
    const onboardingRef = useRef<OnboardingModel | null>(null);

    const { getDatabase } = useDatabase();
    const { ImagesService } = useImagesContext();
    const { userProfileData } = useUserContext();

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const OnboardingService = useMemo(
        () =>
            new Onboarding({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    const { id } = userProfileData ?? { id: '' };

    const updateOnboardingState = useCallback((onboarding: OnboardingModel) => {
        setRSVPOnboardingState({
            isInvitationOnboarded: onboarding.isInvitationOnboarded,
            isAppointmentOnboarded: onboarding.isAppointmentOnboarded,
            isBannerOnboarded: onboarding.isBannerOnboarded,
            isDeclinedAppointmentsOnboarded:
                onboarding.isDeclinedAppointmentsOnboarded,
        });
    }, []);

    useEffect(() => {
        if (isInitialSyncInProgress) {
            return;
        }

        OnboardingService.getAll()
            .fetch()
            .then(([onboarding]) => {
                if (onboarding) {
                    onboardingRef.current = onboarding;
                    updateOnboardingState(onboarding);
                }
            });
    }, [isInitialSyncInProgress, OnboardingService, updateOnboardingState]);

    async function dismissOverlay(overlay: OverlayOption) {
        const payload: OnboardingPayload = {
            ...RSVPOnboardingState,
            userId: id,
        };

        switch (overlay) {
            case 'appointment':
                payload.isAppointmentOnboarded = true;
                break;
            case 'invitation':
                payload.isInvitationOnboarded = true;
                break;
            case 'banner':
                payload.isBannerOnboarded = true;
                break;
            case 'declinedAppointment':
                payload.isDeclinedAppointmentsOnboarded = true;
                break;
        }

        if (onboardingRef.current?.id) {
            const updatedOnboarding = await OnboardingService.update(
                payload,
                onboardingRef.current.id,
            );

            updateOnboardingState(updatedOnboarding);
        } else {
            const newOnboarding = await OnboardingService.add(payload);
            onboardingRef.current = newOnboarding;

            updateOnboardingState(newOnboarding);
        }
    }

    const showRSVPBannerOverlay =
        !!id &&
        !RSVPOnboardingState.isBannerOnboarded &&
        attentionNeededEvents.length > 0 &&
        !isSyncInProgress;

    const showRSVPAppointmentOverlay =
        !!id &&
        !RSVPOnboardingState.isAppointmentOnboarded &&
        !isSyncInProgress;

    const showRSVPInvitationOverlay =
        !!id && !RSVPOnboardingState.isInvitationOnboarded && !isSyncInProgress;

    const showRSVPDeclinedAppointmentOverlay =
        !RSVPOnboardingState.isDeclinedAppointmentsOnboarded &&
        !isSyncInProgress;

    const contextValue: Context.RSVPOverlay.Value = {
        dismissOverlay,
        showRSVPBannerOverlay,
        showRSVPAppointmentOverlay,
        showRSVPInvitationOverlay,
        showRSVPDeclinedAppointmentOverlay,
    };

    return (
        <RSVPOverlayContext.Provider value={contextValue}>
            {children}
        </RSVPOverlayContext.Provider>
    );
};

function useRSVPOverlayContext() {
    const context = useContext(RSVPOverlayContext);
    return context;
}

export { RSVPOverlayContext, RSVPOverlayProvider, useRSVPOverlayContext };
