import { AccountingProviderType } from 'shared/types/Invoicing';

export enum INVOICES_FILTER {
    status = 'status',
    date_range = 'date_range',
    show_overdue = 'show_overdue',
    contacts = 'contacts',
    provider = 'provider',
    archived = 'archived',
    unsynced = 'unsynced',
}

export enum INVOICES_STATUS_FILTER {
    authorised = 'AUTHORISED',
    sent = 'SENT',
    draft = 'DRAFT',
    paid = 'PAID',
    queued = 'QUEUED',
    deleted = 'DELETED',
}

export type ProviderFilter = AccountingProviderType | 'all';
