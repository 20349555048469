import { Q } from '@nozbe/watermelondb';
import { HorsesFiltersObject } from '../types/horsesFilters';
import { CONTACTS_INVOICING_FILTER } from '../constants/contacts/filters';
import {
    ENTRIES_INVOICING_FILTER,
    ENTRIES_SHOW_ENTRIES_FILTER,
} from '../constants/entries/filters';
import {
    INVOICES_STATUS_FILTERS_DICTIONARY,
    INVOICE_STATUS,
} from '../constants/invoices/statuses';
import { ContactsFiltersObject } from '../types/contactsFilters';
import { EntriesFiltersObject } from '../types/entriesFilters';
import { Queries } from '../types/watermelonDb';
import { diffBetweenDatesByRange, getLocal } from '../utils/date';
import { InvoicesFiltersObject } from '../types/invoicesFilters';
import { INVOICES_STATUS_FILTER } from '../constants/invoices/filters';
import moment from '../utils/moment';
import { UnsafeQuery } from '../types/sort';
import EventUser from './services/EventUser';
import { DBWriterLoggerFunction } from '../types/logger';
import User from './services/User';
import { map, switchMap } from 'rxjs';
import Database from '../db/services/Database.web';
import { ImageService } from '../types/imageService';
import { InventoryFiltersObject } from '../types/inventoryFilters';
import { ProductFiltersObject } from '../types/productFilters';
import { ProductGroup, ProductModel, ProductType } from '../types/Products';
import Moment from '../utils/moment';
import { ProductDBServiceOptions } from '../types/dbService';
import { getGroupedProducts } from '../utils/product';
import { UserModel } from '../types/User';

export function doesNamePartMatchSearchFilter(
    name: string | undefined | null,
    search: string,
): boolean {
    // Due to lack of Typescript in loki transforms it's safer to assume that sometimes what we pass can be empty
    if (!name) return false;

    const nameLowercase = name.toLowerCase();
    const searchLowercase = search.trim().toLowerCase();

    // Match beggining of the name, for example `John` in `John Doe`
    // Or match beggining of an another word in the name (we consider words strings separeted by space)
    return (
        nameLowercase.toLowerCase().startsWith(searchLowercase) ||
        nameLowercase.toLowerCase().includes(` ${searchLowercase}`)
    );
}

export function doesContactMatchSearchFilter(
    firstName: string,
    lastName: string | undefined | null,
    search: string,
): boolean {
    const firstNameLowercase = firstName.toLowerCase();
    const lastNameLowercase = lastName?.toLowerCase();
    const searchLowercase = search.toLowerCase();

    if (!lastNameLowercase) {
        return firstNameLowercase.startsWith(searchLowercase);
    }

    const fullName = `${firstNameLowercase} ${lastNameLowercase}`;

    return fullName.startsWith(search) || lastNameLowercase.startsWith(search);
}

export function getUpdatedFieldValue<T, E>(
    value: T | undefined | null,
    previousValue: T,
    valueParser?: (value: T) => E,
) {
    if (value) {
        return valueParser ? valueParser(value) : value;
    }

    if (value === null) {
        return null;
    }

    if (value === undefined) {
        return previousValue;
    }
}

export function applyInventoryFiltersQueries(filters: InventoryFiltersObject) {
    const finalQuery: Q.Clause[] = [];

    if (filters.searchText) {
        const parsedSearchText = filters.searchText.toLowerCase().trim();

        const sanitizedSearch = Q.sanitizeLikeString(parsedSearchText);

        if (sanitizedSearch.length) {
            const SQLiteProductQuery = `brand || iif(family IS NULL, '', ' ') || ifnull(family, '') || iif(material IS NULL, '', ' ') || ifnull(material, '') || iif(shape IS NULL, '', ' ') || ifnull(shape, '') || iif(clips IS NULL, '', ' ') || ifnull(clips, '') LIKE`;

            const queries = [
                Q.unsafeSqlExpr(`${SQLiteProductQuery} '${sanitizedSearch}%'`),
                Q.unsafeSqlExpr(
                    `${SQLiteProductQuery} '% ${sanitizedSearch}%'`,
                ),
                Q.where('name', Q.like(`${sanitizedSearch}%`)),
                Q.where('name', Q.like(`% ${sanitizedSearch}%`)),
                Q.where('sku_apac', Q.like(`${sanitizedSearch}%`)),
                Q.where('sku_apac', Q.like(`% ${sanitizedSearch}%`)),
                Q.where('sku_emea', Q.like(`${sanitizedSearch}%`)),
                Q.where('sku_emea', Q.like(`% ${sanitizedSearch}%`)),
            ];

            if (filters.type) {
                finalQuery.push(
                    Q.on(
                        'products',
                        Q.and(
                            // @ts-ignore
                            Q.or(...queries),
                            Q.where(
                                'product_type',
                                Q.like(Q.sanitizeLikeString(filters.type)),
                            ),
                        ),
                    ),
                );
            } else {
                finalQuery.push(
                    // @ts-ignore
                    Q.on('products', Q.or(...queries)),
                );
            }
        }
    }

    if (filters.type && !filters.searchText) {
        finalQuery.push(Q.on('products', 'product_type', filters.type));
    }

    return finalQuery;
}

export function getUnsafeProductsSearchQueries(search: string) {
    const finalQueries: UnsafeQuery[] = [];

    finalQueries.push((raws) => {
        if (!search.trim().length) return raws;

        return raws.filter((rawRecord) => {
            const searchField =
                rawRecord.product_type !== ProductType.custom
                    ? `${rawRecord.brand ?? ''} ${rawRecord.family ?? ''} ${
                          rawRecord.material ?? ''
                      } ${rawRecord.shape ?? ''} ${rawRecord.clips ?? ''}`
                    : rawRecord.name;

            return doesNamePartMatchSearchFilter(searchField, search);
        });
    });

    return finalQueries;
}

export function getProductsByGroupsQuery(groups: ProductGroup[]) {
    return Q.or(
        ...groups.map((brandAndFamily) => {
            if (
                !brandAndFamily.brand &&
                !brandAndFamily.family &&
                !brandAndFamily.shape &&
                !brandAndFamily.material &&
                !brandAndFamily.clips
            ) {
                return Q.where('name', brandAndFamily.name ?? null);
            }

            return Q.or(
                Q.and(
                    Q.where('family', brandAndFamily.family ?? null),
                    Q.where('brand', brandAndFamily.brand ?? null),
                    Q.where('shape', brandAndFamily.shape ?? null),
                    Q.where('material', brandAndFamily.material ?? null),
                    Q.where('clips', brandAndFamily.clips ?? null),
                ),
            );
        }),
    );
}

export function getProductsSortQuery() {
    return [
        Q.sortBy('name'),
        Q.sortBy('brand'),
        Q.sortBy('family'),
        Q.sortBy('material'),
        Q.sortBy('shape'),
        Q.sortBy('clips'),
        Q.sortBy('shoe_size'),
        Q.sortBy('nail_size'),
    ];
}

export async function applyProductsFiltersQueries(
    filters: ProductFiltersObject,
    options: ProductDBServiceOptions,
) {
    const productsCollection =
        options.database.collections.get<ProductModel>('products');

    const finalQuery: Q.Clause[] = [];
    let productFilterQuery: Q.Clause | null = null;

    if (filters.productFilter === 'often-used') {
        const entryProducts = await productsCollection
            .query(
                Q.on(
                    'entry_products',
                    'created_at',
                    Q.gt(Moment().subtract(3, 'months').valueOf()),
                ),
            )
            .fetch();

        const groupedProducts = getGroupedProducts(entryProducts);

        const productGroups: ProductGroup[] = groupedProducts.map(
            (productsInGroup) => ({
                name: productsInGroup[0].name,
                brand: productsInGroup[0].brand,
                clips: productsInGroup[0].clips,
                family: productsInGroup[0].family,
                shape: productsInGroup[0].shape,
                material: productsInGroup[0].material,
            }),
        );

        productFilterQuery = getProductsByGroupsQuery(productGroups);
    } else if (filters.productFilter === 'from') {
        const inventoryProducts = await productsCollection
            .query(Q.on('inventory_products', 'product_id', Q.notEq(null)))
            .fetch();

        const groupedProducts = getGroupedProducts(inventoryProducts);

        const productGroups: ProductGroup[] = groupedProducts.map(
            (productsInGroup) => ({
                name: productsInGroup[0].name,
                brand: productsInGroup[0].brand,
                clips: productsInGroup[0].clips,
                family: productsInGroup[0].family,
                shape: productsInGroup[0].shape,
                material: productsInGroup[0].material,
            }),
        );

        productFilterQuery = getProductsByGroupsQuery(productGroups);
    }

    if (filters.productType) {
        const productTypeQuery = Q.where('product_type', filters.productType);
        const query = productFilterQuery
            ? Q.and(productFilterQuery, productTypeQuery)
            : productTypeQuery;

        finalQuery.push(Q.experimentalJoinTables(['entry_products']), query);
    } else if (productFilterQuery) {
        finalQuery.push(productFilterQuery);
    }

    if (filters.brand) {
        finalQuery.push(Q.where('brand', filters.brand));
    }

    if (!!filters.excludeProductTypes?.length) {
        finalQuery.push(
            Q.where('product_type', Q.notIn(filters.excludeProductTypes)),
        );
    }

    return finalQuery;
}

export function applyInvoicesFiltersQueries(
    filters: InvoicesFiltersObject,
    userHasProvider?: boolean,
) {
    const statusFilterSize = Object.keys(
        INVOICES_STATUS_FILTERS_DICTIONARY,
    ).length;

    const isStatusFilterNotEmpty = filters.status.length;
    const isStatusFilterNotFull =
        filters.status.length !==
        (userHasProvider ? statusFilterSize : statusFilterSize - 1);

    const isStatusFilterValueSent = filters.status.includes(
        INVOICES_STATUS_FILTER.sent,
    );
    const isDateRangeFilterApplied = filters.date_range.isActive;
    const isOverdueFilterActive = filters.show_overdue;
    const areContactsFiltersApplied = !!filters.contacts.length;
    const isProviderFilterApplied =
        filters.provider && filters.provider !== 'all';

    // STATUS FILTER

    const statusFilterQuery = [Q.where('status', Q.oneOf(filters.status))];
    const hasStatusAuthorised = [
        Q.where('status', INVOICES_STATUS_FILTER.authorised),
    ];
    const hasStatusSent = [Q.where('status', INVOICES_STATUS_FILTER.sent)];
    const sentStatusFilterQuery = [Q.where('sent_to_contact', true)];
    const hasDueDateTime = [Q.where('due_date_time', Q.notEq(null))];

    const userHasProviderFilterQuery = [
        Q.and(
            Q.where('status', INVOICES_STATUS_FILTER.authorised),
            Q.where('sent_to_contact_time', Q.notEq(null)),
            Q.where('sent_to_contact', false),
        ),
    ];

    const now = moment();
    const hasPastDueDateTime = [
        Q.where('due_date_time', Q.lte(now.toISOString())),
    ];

    const hasStatusAuthorisedOrSent = [
        Q.or(...hasStatusAuthorised, ...hasStatusSent),
    ];

    const showOverdueInvoices = [
        ...hasStatusAuthorisedOrSent,
        ...hasDueDateTime,
        ...hasPastDueDateTime,
    ];

    const finalQuery: Q.Clause[] = [];
    const statusQuery: Q.Clause[] = [];

    if (
        isStatusFilterNotEmpty &&
        isStatusFilterNotFull &&
        !isStatusFilterValueSent
    ) {
        statusQuery.push(...statusFilterQuery);

        if (
            userHasProvider &&
            filters.status.includes(INVOICES_STATUS_FILTER.queued)
        ) {
            statusQuery.push(...userHasProviderFilterQuery);
        }
    }

    if (isStatusFilterValueSent) {
        statusQuery.push(...sentStatusFilterQuery);
    }

    // DATE RANGE FILTER

    if (isDateRangeFilterApplied) {
        const { from, to } = filters.date_range;

        if (from && to) {
            const dateQuery = [
                Q.and(
                    Q.where('updated_at', Q.gte(moment(from).valueOf())),
                    Q.where('updated_at', Q.lte(moment(to).valueOf())),
                ),
            ];
            finalQuery.push(...dateQuery);
        }
    }

    // OVERDUE FILTER

    if (isOverdueFilterActive) {
        finalQuery.push(...showOverdueInvoices);
    }

    // CONTACT FILTER

    if (areContactsFiltersApplied) {
        const contactsIdsFilter = filters.contacts.map((contact) => contact.id);
        finalQuery.push(Q.where('contact_id', Q.oneOf(contactsIdsFilter)));
    }

    if (isStatusFilterNotEmpty) {
        finalQuery.push(...statusQuery);
    }

    // PROVIDER FILTER
    if (isProviderFilterApplied) {
        finalQuery.push(Q.where('provider', filters.provider));
    }

    if (filters.archived) {
        finalQuery.push(Q.where('provider_archived_at', Q.notEq(null)));
    }

    if (filters.unsynced === true) {
        finalQuery.push(Q.where('synced', false));
    }

    if (filters.unsynced === false) {
        finalQuery.push(Q.where('synced', true));
    }

    return finalQuery;
}

export function getUnsafeContactsSearchQueries(
    search: string,
    includeBusinessName = false,
) {
    const finalQueries: UnsafeQuery[] = [];

    finalQueries.push((raws, _) => {
        if (!search.trim().length) return raws;

        return raws.filter((rawRecord) => {
            const nameMatchesSearch = doesContactMatchSearchFilter(
                rawRecord.first_name,
                rawRecord.last_name,
                search,
            );

            if (!includeBusinessName) {
                return nameMatchesSearch;
            }

            return (
                nameMatchesSearch ||
                doesNamePartMatchSearchFilter(rawRecord.business_name, search)
            );
        });
    });
    return finalQueries;
}

export function applyContactsFiltersQueries(filters: ContactsFiltersObject) {
    const isHasOpenEntriesFilterApplied = filters.invoicing.includes(
        CONTACTS_INVOICING_FILTER.open_entries,
    );
    const isHasOpenInvoicesFilterApplied = filters.invoicing.includes(
        CONTACTS_INVOICING_FILTER.open_invoices,
    );
    const isBillableFilterApplied = filters.invoicing.includes(
        CONTACTS_INVOICING_FILTER.billable,
    );
    const isHiddenFilterApplied = filters.show_hidden;

    const areInvoicingFiltersApplied = !!filters.invoicing.length;
    const areRoleFiltersApplied = !!filters.role.length;

    const isIdsFilterApplited = filters.show_ids.length > 0;

    const hasOpenEntriesQuery = [
        Q.on(
            'horse_contacts',
            Q.on(
                'horses',
                Q.on(
                    'entries',
                    Q.or(
                        Q.where('invoice_id', Q.oneOf([''])),
                        Q.where('invoice_id', null),
                    ),
                ),
            ),
        ),
    ];

    const hasOpenInvoicesQuery = [
        Q.on(
            'invoices',
            Q.where(
                'status',
                Q.oneOf([INVOICE_STATUS.sent, INVOICE_STATUS.authorised]),
            ),
        ),
    ];

    const billableQuery = [Q.where('billable', true)];

    const roleQuery = [Q.where('role', Q.oneOf(filters.role))];

    const hiddenQuery = [Q.where('hidden', true)];
    const visibleQuery = [Q.where('hidden', false)];

    const hasOpenEntriesOrInvoices = [
        Q.or(...hasOpenEntriesQuery, ...hasOpenInvoicesQuery),
    ];

    const invoicingQueries: Queries = [];
    const finalQueries: Queries = [];

    if (isHasOpenEntriesFilterApplied && !isHasOpenInvoicesFilterApplied) {
        invoicingQueries.push(...hasOpenEntriesQuery);
    }

    if (isHasOpenInvoicesFilterApplied && !isHasOpenEntriesFilterApplied) {
        invoicingQueries.push(...hasOpenInvoicesQuery);
    }

    if (isHasOpenEntriesFilterApplied && isHasOpenInvoicesFilterApplied) {
        invoicingQueries.length = 0;
        invoicingQueries.push(...hasOpenEntriesOrInvoices);
    }

    if (isBillableFilterApplied) {
        invoicingQueries.push(...billableQuery);
    }

    if (isHiddenFilterApplied) {
        finalQueries.push(...hiddenQuery);
    } else {
        finalQueries.push(...visibleQuery);
    }

    if (areRoleFiltersApplied) {
        finalQueries.push(...roleQuery);
    }

    if (areInvoicingFiltersApplied) {
        finalQueries.push(Q.or(...invoicingQueries));
    }

    if (isIdsFilterApplited) {
        finalQueries.push(Q.where('id', Q.oneOf(filters.show_ids)));
    }

    return finalQueries;
}

export function getUnsafeHorsesFiltersQueries(filters: HorsesFiltersObject) {
    const isAgeFilterApplied = !!filters.age.length;
    const finalQueries: UnsafeQuery[] = [];

    if (isAgeFilterApplied) {
        finalQueries.push((raws) => {
            return raws.filter((rawRecord) => {
                const foalDate = rawRecord.foal_date;
                const ageFilter = Number(filters.age) || 0;

                const diff = diffBetweenDatesByRange(
                    getLocal(),
                    foalDate,
                    'years',
                );

                return ageFilter === diff;
            });
        });
    }
    return finalQueries;
}

export function applyHorsesFiltersQueries(filters: HorsesFiltersObject) {
    const isBreedFilterApplied = !!filters.breed.length;
    const isGenderFilterApplied = !!filters.gender.length;
    const isShoeingCycleFilterApplied = !!filters.shoeing_cycle.length;
    const isHiddenFilterApplied = filters.show_hidden;

    const finalQueries: Queries = [];

    if (isBreedFilterApplied) {
        finalQueries.push(Q.where('breed', Q.oneOf(filters.breed)));
    }

    if (isGenderFilterApplied) {
        finalQueries.push(Q.where('gender', Q.oneOf(filters.gender)));
    }

    if (isShoeingCycleFilterApplied) {
        finalQueries.push(
            Q.where(
                'shoeing_cycle_weeks',
                Q.oneOf(filters.shoeing_cycle.map((item) => Number(item))),
            ),
        );
    }

    if (isHiddenFilterApplied) {
        finalQueries.push(Q.where('hidden', true));
    } else {
        finalQueries.push(Q.where('hidden', false));
    }

    return finalQueries;
}

export function applyEntriesSearchQueries(searchText: string): Q.Clause[] {
    const queries = [
        // @ts-ignore
        Q.unsafeLokiTransform((raws, loki) => {
            if (!searchText.trim().length) return raws;

            return raws.filter((rawRecord) => {
                const entryHorse = loki
                    .getCollection('horses')
                    .by('id', rawRecord.horse_id);

                if (!entryHorse) {
                    return false;
                }

                const entryHorseContacts = loki
                    .getCollection('horse_contacts')
                    .find({ horse_id: { $eq: entryHorse.id } });

                const entryHorseContactsIds: Array<string> =
                    entryHorseContacts.map(
                        (horseContact) => horseContact.contact_id,
                    );

                const horseContacts: Array<{
                    first_name: string;
                    last_name: string;
                }> = entryHorseContactsIds.map((contactId) => {
                    return loki.getCollection('contacts').by('id', contactId);
                });

                const isEntryContactNameMatch = horseContacts.some((contact) =>
                    doesContactMatchSearchFilter(
                        contact.first_name,
                        contact.last_name,
                        searchText,
                    ),
                );

                const isEntryHorseNameMatch = doesNamePartMatchSearchFilter(
                    entryHorse.name,
                    searchText,
                );

                return isEntryHorseNameMatch || isEntryContactNameMatch;
            });
        }),
    ];

    return queries;
}

export function applyEntriesFiltersQueries(filters: EntriesFiltersObject) {
    const areShowEntriesFiltersApplied = !!filters.horse_assigned.length;
    const areInvoicingFiltersApplied = !!filters.invoicing.length;
    const isDateRangeFilterApplied = filters.date_range.isActive;
    const areContactsFiltersApplied = !!filters.contacts.length;
    const areJobTypeFiltersApplied = !!filters.job_type.length;

    const finalQueries: Q.Clause[] = [];

    if (areShowEntriesFiltersApplied) {
        const showEntriesQueries: Q.Clause[] = [];

        const showEntriesWithHorseAssignedQuery = [
            Q.or(
                Q.where('horse_id', Q.notEq(null)),
                Q.where('horse_id', Q.notIn([''])),
            ),
        ];
        const showEntriesWithoutHorseAssignedQuery = [
            Q.or(Q.where('horse_id', null), Q.where('horse_id', Q.oneOf(['']))),
        ];

        const isShowEntriesWithHorseAssignedFilterApplied =
            filters.horse_assigned.includes(
                ENTRIES_SHOW_ENTRIES_FILTER.with_horse_assigned,
            );
        const isShowEntriesWithoutHorseAssignedFilterApplied =
            filters.horse_assigned.includes(
                ENTRIES_SHOW_ENTRIES_FILTER.without_horse_assigned,
            );

        if (
            isShowEntriesWithHorseAssignedFilterApplied &&
            !isShowEntriesWithoutHorseAssignedFilterApplied
        ) {
            showEntriesQueries.push(...showEntriesWithHorseAssignedQuery);
        }

        if (
            isShowEntriesWithoutHorseAssignedFilterApplied &&
            !isShowEntriesWithHorseAssignedFilterApplied
        ) {
            showEntriesQueries.push(...showEntriesWithoutHorseAssignedQuery);
        }

        if (
            isShowEntriesWithHorseAssignedFilterApplied &&
            isShowEntriesWithoutHorseAssignedFilterApplied
        ) {
            showEntriesQueries.push(
                Q.or(
                    ...showEntriesWithHorseAssignedQuery,
                    ...showEntriesWithoutHorseAssignedQuery,
                ),
            );
        }

        finalQueries.push(...showEntriesQueries);
    }

    if (areInvoicingFiltersApplied) {
        const invoicingQueries: Queries = [];

        const isToInvoiceFilterApplied = filters.invoicing.includes(
            ENTRIES_INVOICING_FILTER.to_invoice,
        );
        const isDraftInvoiceFilterApplied = filters.invoicing.includes(
            ENTRIES_INVOICING_FILTER.draft_invoice,
        );
        const isInvoicedFilterApplied = filters.invoicing.includes(
            ENTRIES_INVOICING_FILTER.invoiced,
        );

        const todayDate = getLocal();

        if (isToInvoiceFilterApplied) {
            invoicingQueries.push(
                Q.and(
                    Q.where('logged_time', Q.lt(todayDate.toISO())),
                    Q.or(
                        Q.where('invoice_id', null),
                        Q.where('invoice_id', Q.oneOf([''])),
                    ),
                ),
            );
        }

        if (isDraftInvoiceFilterApplied) {
            invoicingQueries.push(
                Q.on('invoices', Q.where('status', Q.eq(INVOICE_STATUS.draft))),
            );
        }

        if (isInvoicedFilterApplied) {
            invoicingQueries.push(
                Q.on(
                    'invoices',
                    Q.where(
                        'status',
                        Q.oneOf([
                            INVOICE_STATUS.approved,
                            INVOICE_STATUS.sent,
                            INVOICE_STATUS.authorised,
                            INVOICE_STATUS.paid,
                        ]),
                    ),
                ),
            );
        }

        finalQueries.push(Q.or(...invoicingQueries));
    }

    if (isDateRangeFilterApplied) {
        const { from, to } = filters.date_range;

        if (from && to) {
            finalQueries.push(
                Q.and(
                    Q.where('logged_time', Q.gte(moment(from).toISOString())),
                    Q.where('logged_time', Q.lte(moment(to).toISOString())),
                ),
            );
        }
    }

    if (areContactsFiltersApplied) {
        const contactsIdsFilter = filters.contacts.map((contact) => contact.id);

        finalQueries.push(
            Q.experimentalJoinTables(['horses']),
            Q.experimentalNestedJoin('horses', 'horse_contacts'),
            Q.on(
                'horses',
                Q.on(
                    'horse_contacts',
                    'contact_id',
                    Q.oneOf(contactsIdsFilter),
                ),
            ),
        );
    }

    if (areJobTypeFiltersApplied) {
        finalQueries.push(Q.where('title', Q.oneOf(filters.job_type)));
    }

    return finalQueries;
}

export const getUsersByEventId = (
    eventId: string,
    imageService: ImageService,
) => {
    const database = Database.getDatabase();

    const logDBActionMock: DBWriterLoggerFunction = () => {
        return;
    };

    const userService = new User({
        database,
        imageService,
        logDBAction: logDBActionMock,
    });
    const eventUserService = new EventUser({
        database,
        imageService,
        logDBAction: logDBActionMock,
    });

    const users = eventUserService.getEventUsersIdsById(eventId).pipe(
        switchMap((eventMembers: string[]) =>
            userService
                .get()
                .observe()
                .pipe(
                    map((users: UserModel[]) =>
                        users.filter((user) =>
                            eventMembers.some(
                                (eventMember) => eventMember === user.id,
                            ),
                        ),
                    ),
                ),
        ),
    );

    return users;
};

export function applyEventsSearchQueries(searchText: string): Q.Clause[] {
    const queries = [
        Q.or(
            Q.where('title', Q.like(`%${Q.sanitizeLikeString(searchText)}%`)),
            Q.on(
                'event_horses',
                Q.on(
                    'horses',
                    Q.where(
                        'name',
                        Q.like(`%${Q.sanitizeLikeString(searchText)}%`),
                    ),
                ),
            ),
            Q.on(
                'event_contacts',
                Q.on(
                    'contacts',
                    // @ts-ignore
                    Q.unsafeSqlExpr(
                        `contacts.first_name || ' ' || contacts.last_name like '${Q.sanitizeLikeString(
                            searchText,
                        )}%'`,
                    ),
                ),
            ),
        ),
    ];

    return queries;
}
