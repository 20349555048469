import { Collection, Database, Q } from '@nozbe/watermelondb';

import {
    OrganisationModel,
    OrganisationPayload,
} from '../../types/Organisation';
import { DBServiceOptionsWithImages } from '../../types/dbService';
import { INVOICE_STATUS } from 'shared/constants/invoices/statuses';
import Invoice from './Invoice';
import { InvoiceModel } from 'shared/types/Invoice';
import InvoiceInvoicingProduct from '../models/InvoiceInvoicingProduct';
import { calculateInvoiceSum } from 'shared/utils/invoicing';

class Organisation {
    private database: Database;
    private collection: Collection<OrganisationModel>;
    private table = 'organisations';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    get() {
        return this.collection.query().fetch();
    }

    getCurrentOrganisationQuery() {
        return this.collection.query();
    }

    getById(id: string) {
        return this.collection.query(Q.where('id', id)).fetch();
    }

    async update(payload: OrganisationPayload, userId: string) {
        const serviceOptions: DBServiceOptionsWithImages = {
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        };

        const {
            image,
            accountingProvider,
            avatar,
            removeAvatar,
            address,
            alternativeAddress,
            paymentInstructons,
            alternativeEmail,
            alternativePhone,
            alternativePhonePrefix,
            footerMessage,
            taxRate,
            productsTaxRate,
            businessNumber,
            isAlternativePhoneEnabled,
            isAlternativeEmailEnabled,
            updateInvoicesProductsTaxRate,
            updateInvoicesTaxRate,
        } = payload;

        const organiastionElement = await this.get();
        const { id: organisationID } = organiastionElement[0];

        const updatedOrganisation = await this.database.write(async () => {
            const updatedOrganisationElement =
                await organiastionElement[0].update((organisation) => {
                    organisation.name =
                        payload.name || organiastionElement[0].name;
                    organisation.address =
                        address || organiastionElement[0].address;
                    organisation.alternativeAddress =
                        alternativeAddress ||
                        organiastionElement[0].alternativeAddress;
                    organisation.paymentInstructions =
                        paymentInstructons ||
                        organiastionElement[0].paymentInstructions;
                    organisation.alternativeEmail =
                        alternativeEmail ||
                        organiastionElement[0].alternativeEmail;
                    organisation.alternativePhoneNumber =
                        alternativePhone ||
                        organiastionElement[0].alternativePhoneNumber;
                    organisation.footerMessage =
                        footerMessage || organiastionElement[0].footerMessage;
                    organisation.taxRate =
                        taxRate || organiastionElement[0].taxRate;
                    organisation.productsTaxRate =
                        productsTaxRate ||
                        organiastionElement[0].productsTaxRate;
                    organisation.businessNumber =
                        businessNumber || organiastionElement[0].businessNumber;
                    organisation.alternativePhonePrefix =
                        alternativePhonePrefix ||
                        organiastionElement[0].alternativePhonePrefix;
                    organisation.isAlternativePhoneEnabled =
                        isAlternativePhoneEnabled;
                    organisation.isAlternativeEmailEnabled =
                        isAlternativeEmailEnabled;
                });

            this.options.logDBAction({
                message: 'Update organisation',
                modelName: this.table,
                payload: organiastionElement[0],
            });

            return updatedOrganisationElement;
        });

        if (removeAvatar) {
            await this.options.imageService.remove(organisationID);
        }

        if (image && image.uri) {
            this.options.imageService.uploadImage({
                image,
                entityID: organisationID,
                entityType: 'Organisation',
                annotationImage: '',
                ownerID: userId,
                userIDs: [userId],
                documentID: avatar?.documentID,
                organisationID,
            });
        }

        if (
            (updateInvoicesTaxRate || updateInvoicesProductsTaxRate) &&
            accountingProvider
        ) {
            const invoiceService = new Invoice(serviceOptions);

            const draftInvoices = await invoiceService.fetchByParams([
                { name: 'status', value: INVOICE_STATUS.draft },
                { name: 'provider', value: accountingProvider },
            ]);

            let updatedInvoices: InvoiceModel[] = [];

            for (let draftInvoice of draftInvoices) {
                let totalToUpdate = draftInvoice.total;

                if (draftInvoice.applyTaxes) {
                    const products = await this.database.collections
                        .get<InvoiceInvoicingProduct>(
                            'invoice_invoicing_products',
                        )
                        .query(Q.where('invoice_id', draftInvoice.id))
                        .fetch();

                    const entries = await draftInvoice.entries.fetch();
                    const entriesIds = entries.map((entry) => ({
                        id: entry.id,
                    }));

                    const { totalSum } = await calculateInvoiceSum({
                        selectedEntriesIds: entriesIds,
                        products,
                        taxRate: Number(taxRate) || 0,
                        productsTaxRate: Number(productsTaxRate) || 0,
                        options: {
                            database: this.database,
                            imageService: this.options.imageService,
                            logDBAction: this.options.logDBAction,
                        },
                    });

                    totalToUpdate = totalSum.toString();
                }

                const updatedInvoice = await draftInvoice.prepareUpdate(
                    (record) => {
                        if (updateInvoicesTaxRate) {
                            record.taxRate = taxRate;
                        }

                        if (updateInvoicesProductsTaxRate) {
                            record.productsTaxRate = productsTaxRate;
                        }

                        record.total = totalToUpdate;
                    },
                );

                updatedInvoices.push(updatedInvoice);
            }

            this.database.batch(...updatedInvoices);
        }

        return updatedOrganisation;
    }
}

export default Organisation;
