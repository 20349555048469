import { Model } from '@nozbe/watermelondb';

import { ProductSalesUnitModel } from './ProductSalesUnit';

export type ProductQuantities = Record<
    string,
    { quantity: string; unitType?: string }
>;
export type AddProductParams = {
    id: string;
    quantity: string;
    unitType?: string;
};

export enum ProductType {
    shoes = 'shoes',
    nails = 'nails',
    rasps = 'rasps',
    tools = 'tools',
    other = 'other',
    custom = 'custom',
}
export type ProductFilter = 'none' | 'often-used' | 'from' | 'brands';

export type CustomProduct = {
    name: string;
    quantity: number;
    favourite: boolean;
    productType: ProductType.custom;
};

export type Product = {
    id: string;
    name: string;
    productType: ProductType;
    brand: string;
    family: string;
    material: string | null;
    shape: string | null;
    clips: string | null;
    dimensions: string | null;
    shoeSize: string | null;
    nailSize: string | null;
    productSize: string | null;
    shoe_size_sort_num: string | null;
    archived_at: string | null;
    quantity: number;
    favourite: boolean;
    created_at: string;
    added_to_inventory_at: string | null;
    notAvailable?: boolean;
};

export type ProductModel = {
    name: string;
    productType: ProductType;
    brand?: string | null;
    family?: string | null;
    shape?: string | null;
    dimensions?: string | null;
    clips?: string | null;
    shoeSize?: string | null;
    nailSize?: string | null;
    productSize?: string | null;
    skuApac?: string | null;
    skuEmea?: string | null;
    uomDealers?: string | null;
    material?: string | null;
    shoeSizeSortNum?: string | null;
    userId: string;
    productSalesUnits?: ProductSalesUnitModel[];
    boxUnitSize: number;
    organisationId: string;
    notAvailable?: boolean;
} & Model;

export type InventoryProduct = {
    stockProductId: string;
    name: string;
    materialShapeAndClips: string | null;
    type: ProductType;
    dimensions: string | null;
    showAll: boolean;
    productSizes: InventoryProductSize[];
};

export type InventoryProductSize = {
    productId: string;
    inventoryProductId: string;
    quantity: number;
    size: string;
    isInInventory: boolean;
    isHighlighted: boolean;
    notAvailable?: boolean;
};

export type ProductGroup = {
    family?: string | null;
    brand?: string | null;
    shape?: string | null;
    material?: string | null;
    clips?: string | null;
    name?: string | null;
};

export type ProductPayload = {
    name: string;
    productType: ProductType;
    brand?: string | null;
    family?: string | null;
    shape?: string | null;
    dimensions?: string | null;
    clips?: string | null;
    shoeSize?: string | null;
    nailSize?: string | null;
    productSize?: string | null;
    skuApac?: string | null;
    skuEmea?: string | null;
    uomDealers?: string | null;
    material?: string | null;
    shoeSizeSortNum?: string | null;
    userId?: string;
    productSalesUnits?: ProductSalesUnitModel[];
    boxUnitSize: number;
    notAvailable?: boolean;
};

export interface ProductInvoiceModel extends Model {
    id: string;
    name: string;
    price: string;
    available: boolean;
    userId: string;
    organisationId: string;
}

export interface ProductInvoicePayload {
    name: string;
    price: string;
    available: boolean;
    userID: string;
    organisationID: string;
}
