import { Collection, Database, Model, Q } from '@nozbe/watermelondb';
import { isEmpty } from 'lodash';

import Entry from './Entry';
import EventHorse from './EventHorse';
import HorseContact from './HorseContact';
import Location from './Location';
import Organisation from './Organisation';
import { DBServiceOptionsWithImages } from '../../types/dbService';
import { HorseContactModel } from '../../types/HorseContact';
import { HorseModel, HorsePayload } from '../../types/Horses';
import { HorsesFiltersObject } from '../../types/horsesFilters';
import { copyClassInstance } from '../../utils/classes';
import {
    applyHorsesFiltersQueries,
    getUnsafeHorsesFiltersQueries,
    getUpdatedFieldValue,
} from '../utils';
import { Queries } from '../../types/watermelonDb';
import { SortObject, UnsafeQuery } from '../../types/sort';
import { LocationModel } from '../../types/Location';
import { getDuplicatesFreeArrayByKey } from 'shared/utils/array';

class Horse {
    private database: Database;
    private collection: Collection<HorseModel>;
    private table = 'horses';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    countAll() {
        return this.collection.query().fetchCount();
    }

    observeCount() {
        return this.collection.query().observeCount();
    }

    getAll(...queries: Q.Clause[]) {
        return this.collection.query(...queries).fetch();
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    private filterHorses(
        searchText: string,
        filters?: HorsesFiltersObject,
        sort?: SortObject | null,
    ) {
        const parsedSearchText = searchText.toLowerCase().trim();
        const queries: Queries = [];

        if (parsedSearchText) {
            queries.push(
                Q.where(
                    'name',
                    Q.like(`${Q.sanitizeLikeString(parsedSearchText)}%`),
                ),
            );
        }

        if (filters) {
            queries.push(...applyHorsesFiltersQueries(filters));

            const unsafeQueries = getUnsafeHorsesFiltersQueries(filters);
            queries.push(
                //@ts-ignore
                Q.unsafeLokiTransform((raws, loki) => {
                    let result = raws;

                    //calculating unsafe filters and applying it into result
                    unsafeQueries.forEach(
                        (query: UnsafeQuery) => (result = query(result, loki)),
                    );

                    //applying unsafe sort logic over the updated result array
                    if (sort && sort.unsafeQuery)
                        result = sort.unsafeQuery(result, sort.value, loki);

                    return result;
                }),
            );
        }

        const sortQueries: Queries = [];
        if (sort && sort.query && sort.value) {
            const sortQuery = sort.query(sort.value);
            sortQueries.push(sortQuery);
        }

        return this.collection.query(...queries).extend(...sortQueries);
    }

    getFilteredHorsesCount(searchText: string, filters?: HorsesFiltersObject) {
        const query = this.filterHorses(searchText, filters);
        return query.fetchCount();
    }

    getFilteredHorses(
        searchText: string,
        sort: SortObject | null,
        filters?: HorsesFiltersObject,
    ) {
        const query = this.filterHorses(searchText, filters, sort);
        return query.fetch();
    }

    getBadgesNumber() {
        return this.collection
            .query(Q.where('shoeing_cycle_badge', true))
            .fetchCount();
    }

    async getIsRelatedToInvoice(horseId: string) {
        const result = await this.database.collections
            .get('entries')
            .query(
                Q.and(
                    Q.where('horse_id', horseId),
                    Q.where('invoice_id', Q.notEq('')),
                ),
            )
            .fetchCount();

        return result > 0;
    }

    getHorsesByContactNameOrHorseName = async (searchText: string) => {
        // it could return duplicated horses - no idea why
        const result = await this.collection
            .query(
                Q.experimentalJoinTables(['horse_contacts']),
                Q.experimentalNestedJoin('horse_contacts', 'contacts'),
                Q.unsafeSqlQuery(
                    'select horses.* from horses ' +
                        'left join horse_contacts on horses.id is horse_contacts.horse_id ' +
                        'left join contacts on horse_contacts.contact_id is contacts.id ' +
                        `where (horses.name like '${Q.sanitizeLikeString(
                            searchText,
                        )}%' or horses.name like '% ${Q.sanitizeLikeString(
                            searchText,
                        )}%' or contacts.first_name || ' ' || ifnull(contacts.last_name, '') like '${Q.sanitizeLikeString(
                            searchText,
                        )}%' or contacts.first_name || ' ' || ifnull(contacts.last_name, '') like '% ${Q.sanitizeLikeString(
                            searchText,
                        )}%' or contacts.first_name like '${Q.sanitizeLikeString(
                            searchText,
                        )}%' or contacts.last_name like '${Q.sanitizeLikeString(
                            searchText,
                        )}%') and contacts._status is not 'deleted'
                        and horse_contacts._status is not 'deleted'
                        and horses._status is not 'deleted'`,
                ),
            )
            .fetch();

        const uniqueResult = getDuplicatesFreeArrayByKey(result, 'id');

        return uniqueResult;
    };

    async add(payload: HorsePayload, userId: string) {
        const { contacts, image, location } = payload;

        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        const locationService = new Location({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        let locationElement: LocationModel | undefined;

        if (location) {
            locationElement = await locationService.addUnique(location, userId);
        }

        const newHorse = await this.database.write(async () => {
            const createdHorse = await this.collection.create((horse) => {
                horse.name = payload.name;
                horse.foalDate = payload.foalDate;
                horse.gender = payload.gender;
                horse.shoeSize = payload.shoeSize;
                horse.shoeType = payload.shoeType;
                horse.shoeingCycle = payload.shoeingCycle;
                horse.shoeingCycleNotify = payload.shoeingCycleNotify;
                horse.shoeingCycleBadge = false;
                horse.hidden = !!payload.hidden;
                horse.breed = payload.breed;
                horse.workType = payload.workType;
                horse.comments = payload.comments || '';
                horse.stableName = payload.stableName;
                horse.stableAddress = payload.stableAddress;
                horse.stablePhone = payload.stablePhone;
                horse.stablePhonePrefix = payload.stablePhonePrefix;
                horse.microchipNo = payload.microchipNo;
                horse.sireName = payload.sireName;
                horse.damName = payload.damName;
                horse.locationID = locationElement?.id
                    ? locationElement.id
                    : null;
                horse.userId = userId;
                horse.organisationId = organisationID;
            });

            this.options.logDBAction({
                message: 'Create horse',
                modelName: this.table,
                payload: createdHorse,
            });

            if (contacts?.length && contacts.length > 0) {
                const contactsContactsCollection =
                    this.database.collections.get<HorseContactModel>(
                        'horse_contacts',
                    );

                await Promise.all(
                    contacts.map(async (contact) => {
                        const createdContact =
                            await contactsContactsCollection.create(
                                (horseContact) => {
                                    horseContact.horseId = createdHorse.id;
                                    horseContact.userId = createdHorse.userId;
                                    horseContact.organisationId =
                                        createdHorse.organisationId;
                                    horseContact.contactId = contact.id;
                                },
                            );

                        this.options.logDBAction({
                            message: 'Create horse - create contact',
                            modelName: this.table,
                            payload: createdContact,
                        });
                    }),
                );
            }

            return createdHorse;
        });

        if (image && image.uri) {
            // create a new entry in Firestore DB
            await this.options.imageService.uploadImage({
                image,
                entityID: newHorse.id,
                entityType: 'Horse',
                annotationImage: '',
                ownerID: userId,
                userIDs: [userId],
                organisationID,
            });
        }

        return newHorse;
    }

    async update(
        id: string,
        payload: Partial<HorsePayload>,
        userId: string,
    ): Promise<HorseModel> {
        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        const locationService = new Location({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        let locationElement: LocationModel | undefined;

        if (payload.location) {
            locationElement = await locationService.addUnique(
                payload.location,
                userId,
            );
        }

        const horseElement = await this.getByID(id);
        const { contacts, image, removeAvatar } = payload;

        let horseContactsToAdd: HorseContactModel[] = [];
        let horseContactsToDelete: HorseContactModel[] = [];

        const updatedHorseElement = await this.database.write(async () => {
            const updatedHorse = await horseElement.update((horse) => {
                horse.name = payload.name || horseElement.name;
                horse.foalDate = getUpdatedFieldValue(
                    payload.foalDate,
                    horseElement.foalDate,
                );
                horse.gender = getUpdatedFieldValue(
                    payload.gender,
                    horseElement.gender,
                );
                horse.shoeSize = getUpdatedFieldValue(
                    payload.shoeSize,
                    horseElement.shoeSize,
                );
                horse.shoeType = getUpdatedFieldValue(
                    payload.shoeType,
                    horseElement.shoeType,
                );
                horse.shoeingCycle = getUpdatedFieldValue(
                    payload.shoeingCycle,
                    horseElement.shoeingCycle,
                );
                horse.breed = getUpdatedFieldValue(
                    payload.breed,
                    horseElement.breed,
                );
                horse.workType = getUpdatedFieldValue(
                    payload.workType,
                    horseElement.workType,
                );
                horse.hidden =
                    payload.hidden === undefined
                        ? horseElement.hidden
                        : payload.hidden;
                horse.comments = getUpdatedFieldValue(
                    payload.comments,
                    horseElement.comments,
                );
                horse.stableName = getUpdatedFieldValue(
                    payload.stableName,
                    horseElement.stableName,
                );
                horse.stableAddress = getUpdatedFieldValue(
                    payload.stableAddress,
                    horseElement.stableAddress,
                );
                horse.stablePhone = getUpdatedFieldValue(
                    payload.stablePhone,
                    horseElement.stablePhone,
                );
                horse.stablePhonePrefix = getUpdatedFieldValue(
                    payload.stablePhonePrefix,
                    horseElement.stablePhonePrefix,
                );
                horse.microchipNo = getUpdatedFieldValue(
                    payload.microchipNo,
                    horseElement.microchipNo,
                );
                horse.sireName = getUpdatedFieldValue(
                    payload.sireName,
                    horseElement.sireName,
                );
                horse.damName = getUpdatedFieldValue(
                    payload.damName,
                    horseElement.damName,
                );
                horse.locationID =
                    payload.location === undefined
                        ? horseElement.locationID
                        : !isEmpty(locationElement) && locationElement?.id
                        ? locationElement.id
                        : null;
                horse.userId = horseElement.userId;
                horse.organisationId = horseElement.organisationId;

                if (horseElement.hidden) {
                    horse.shoeingCycleNotify = false;
                    horse.shoeingCycleBadge = false;
                } else {
                    horse.shoeingCycleNotify =
                        payload.shoeingCycleNotify === undefined
                            ? horseElement.shoeingCycleNotify
                            : payload.shoeingCycleNotify;
                    horse.shoeingCycleBadge =
                        (payload.shoeingCycleNotify === false
                            ? false
                            : horseElement.shoeingCycleBadge) ||
                        horseElement.shoeingCycleBadge;
                }
            });

            const initialContacts = await horseElement.contacts.fetch();
            const horseContactsCollection =
                this.database.collections.get<HorseContactModel>(
                    'horse_contacts',
                );

            const horseContactService = new HorseContact({
                database: this.database,
                imageService: this.options.imageService,
                logDBAction: this.options.logDBAction,
            });

            const horseContacts = await horseContactService.getByParam(
                'horse_id',
                horseElement.id,
            );

            if (!!contacts) {
                await Promise.all(
                    contacts.map(async (contact) => {
                        if (
                            !initialContacts.find(({ id }) => id === contact.id)
                        ) {
                            const horseContactToAdd =
                                await horseContactsCollection.prepareCreate(
                                    (horseContact) => {
                                        horseContact.horseId = horseElement.id;
                                        horseContact.userId =
                                            horseElement.userId;
                                        horseContact.organisationId =
                                            horseElement.organisationId;
                                        horseContact.contactId = contact.id;
                                    },
                                );

                            horseContactsToAdd = [
                                ...horseContactsToAdd,
                                horseContactToAdd,
                            ];
                        }
                    }),
                );
            }

            if (!!contacts) {
                await Promise.all(
                    horseContacts.map((horseContact) => {
                        if (
                            !contacts?.find(
                                ({ id }) => id === horseContact.contactId,
                            )
                        ) {
                            horseContactsToDelete = [
                                ...horseContactsToDelete,
                                horseContact.prepareMarkAsDeleted(),
                            ];
                        }
                    }),
                );
            }

            this.database.batch(
                ...horseContactsToAdd,
                ...horseContactsToDelete,
            );

            return updatedHorse;
        });

        if (removeAvatar) {
            await this.options.imageService.remove(id);
        }

        if (image && image.uri) {
            await this.options.imageService.uploadImage({
                image,
                entityID: id,
                entityType: 'Horse',
                annotationImage: '',
                userIDs: [userId],
                documentID: image.documentID,
                organisationID,
            });
        }

        // It's not enough to return the updated element as the updated
        // element has the same reference as the one before the update.
        // So to make React able to detect a difference between the objects
        // (different references) we have to make a copy of this class instance.

        return copyClassInstance(updatedHorseElement);
    }

    async bump(ids: string[]) {
        await this.database.write(async () => {
            const horseElements = await Promise.all(
                ids.map(async (id) => this.getByID(id)),
            );

            const updates = horseElements.map((horseElement: HorseModel) =>
                horseElement.prepareUpdate((horse: HorseModel) => {
                    horse.name = horseElement.name;
                }),
            );

            this.database.batch(...updates);
        });
    }

    async deleteByID(id: string, userId: string) {
        const horseElement = await this.getByID(id);
        const contactHorses = await horseElement.contacts.fetch();
        const entries = await horseElement.entries.fetch();
        const events = await horseElement.events.fetch();

        const entryService = new Entry({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        let horseContactsToDelete: Model[] = [];
        let entriesToDelete: Model[] = [];
        let eventsHorseToDelete: Model[] = [];

        await this.database.write(async () => {
            await horseElement.markAsDeleted();

            // Delete related contacts to the horse (horse_contacts)
            if (contactHorses.length > 0) {
                const horseContactService = new HorseContact({
                    database: this.database,
                    imageService: this.options.imageService,
                    logDBAction: this.options.logDBAction,
                });

                const horseContacts = await horseContactService.getByParam(
                    'horse_id',
                    id,
                );

                await Promise.all(
                    horseContacts.map((horseContact) => {
                        horseContactsToDelete = [
                            ...horseContactsToDelete,
                            horseContact.prepareMarkAsDeleted(),
                        ];
                    }),
                );
            }

            // Delete related events to the horse (event_horses)
            if (events.length > 0) {
                const eventHorseService = new EventHorse({
                    database: this.database,
                    imageService: this.options.imageService,
                    logDBAction: this.options.logDBAction,
                });

                const eventsHorse = await eventHorseService.getByParam(
                    'horse_id',
                    id,
                );

                await Promise.all(
                    eventsHorse.map((eventHorse) => {
                        eventsHorseToDelete = [
                            ...eventsHorseToDelete,
                            eventHorse.prepareMarkAsDeleted(),
                        ];
                    }),
                );
            }

            // Delete related entries to the horse
            if (entries.length > 0) {
                const deletePromises = entries.map((e) =>
                    entryService.prepareDeleteById(e.id, userId),
                );

                entriesToDelete = (await Promise.all(deletePromises)).flatMap(
                    (c) => c,
                );
            }

            this.database.batch(
                ...horseContactsToDelete,
                ...entriesToDelete,
                ...eventsHorseToDelete,
            );
        });

        this.options.imageService.remove(id);
    }
}

export default Horse;
